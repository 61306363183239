import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoGatsbyImage } from '@/components/DatoGatsbyImage'
import { DatoStructuredText } from '@/components/DatoStructuredText'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { LeaderThumbnail } from '../LeadershipSection/LeaderThumbnail'

interface Props extends ComponentPropsWithoutRef<'section'> {
  heading?: string | null
  body?: any
  headshot?: Queries.WelcomeLetterHeadshotFragment | null
  leaders?: readonly (Queries.LeaderThumbnailFragment | null)[] | null
}
export const WelcomeLetter = ({
  heading,
  body,
  headshot,
  leaders,
  ...props
}: Props): JSX.Element => {
  const styles = {
    section: css`
      background: ${colors.gray90};
      padding: 0 calc(var(--margin) + var(--headshot-width) / 2);
      position: relative;
      display: grid;
      justify-content: center;
      --headshot-width: calc(1.5 * var(--fs-144));
      ${mq().ml} {
        padding: 0 var(--margin);
      }
      ${mq().s} {
        --headshot-width: var(--fs-144);
        padding: 0.5rem 0.5rem 0;
      }
    `,
    letter: css`
      position: relative;
      background: #fff;
      padding: var(--row-72)
        calc(var(--margin) + var(--headshot-width) / 2) var(--row-108);
      max-width: 75ch;
      ${mq().ml} {
        padding: var(--row-72) calc(1.5 * var(--margin)) var(--row-108);
      }
      ${mq().s} {
        display: grid;
        grid-template-columns: auto 1fr;
        padding: var(--row-72) var(--margin) var(--row-108);
      }
      ${headshot &&
      css`
        padding-bottom: calc(var(--row-108) + var(--row-36));
        ${mq().ml} {
          padding-left: calc(
            var(--headshot-width) + 0.25 * var(--margin)
          );
        }
        ${mq().s} {
          padding: var(--row-72) 0 var(--row-108);
        }
      `}
    `,
    headshot: css`
      width: var(--headshot-width);
      height: auto;
      border: 0.5rem solid #fff;
      border-radius: 50%;
      position: absolute;
      top: var(--row-72);
      left: 0;
      transform: translateX(-50%);
      ${mq().ml} {
        transform: translateX(calc(-0.75 * var(--margin)));
      }
      ${mq().s} {
        top: auto;
        position: relative;
        align-self: flex-start;
        border-width: 0.333rem;
        margin-left: var(--margin);
      }
    `,
    heading: css`
      font-size: var(--fs-60);
      color: ${colors.gray10};
      margin: 0.333em 0 0.75em;
      max-width: 24ch;
      display: flex;
      flex-direction: column;
      &:after {
        content: '';
        height: 2px;
        background: ${colors.red};
        width: min(100%, 2em);
        margin-top: 0.75em;
      }
      ${mq().s} {
        margin-right: var(--margin);
      }
    `,
    body: css`
      line-height: 1.75;
      ${mq().s} {
        grid-column: 1 / -1;
        ${headshot &&
        css`
          margin: 0 var(--margin);
        `}
      }
    `,
    leaders: css`
      display: grid;
      margin-top: 3em;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2em;
      grid-row-gap: 2em;
      ${mq().s} {
        grid-template-columns: 1fr;
        margin-top: 1em;
        grid-row-gap: 1em;
      }
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <div css={styles.letter}>
        {headshot && (
          <DatoGatsbyImage
            css={styles.headshot}
            image={headshot.gatsbyImageData}
            alt={headshot.alt || ''}
          />
        )}
        <h2 css={styles.heading}>{heading}</h2>
        <div css={styles.body}>
          <DatoStructuredText data={body} />
        </div>
        {leaders && (
          <div css={styles.leaders}>
            {leaders.map((leader, i) => (
              <LeaderThumbnail
                data={leader}
                styleVariant="HORIZONTAL"
                key={i}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export const WelcomeLetterFragment = graphql`
  fragment WelcomeLetterHeadshot on DatoCmsFileField {
    gatsbyImageData(
      imgixParams: { fit: "crop", crop: "focalpoint", q: 50, ar: "1:1" }
      width: 360
    )
    alt
  }
`
